export default {
  methods: {
    resolveProgressVariant(progress) {
      if (progress <= 25) return 'error'
      if (progress > 25 && progress <= 50) return 'warning'
      if (progress > 50 && progress <= 75) return 'primary'
      if (progress > 75 && progress <= 100) return 'success'

      return 'secondary'
    },
    resolveStatusVariant(status) {
      if (status === 'Pending') return 'warning'
      if (status === 'Active') return 'success'
      if (status === 'Vaccinated') return 'success'
      if (status === 'Inactive') return 'secondary'
      if (status === 'Skipped') return 'error'

      return 'primary'
    },
  },
}
