<template>
   <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="corporateData.icon ? '' : 'primary'"
            :class="corporateData.icon ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="corporateData.icon"
              :src="require(`@/assets/images/company/${corporateData.icon}`)"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(corporateData.name) }}</span>
          </v-avatar>

          <span class="mb-2">{{ corporateData.name }}</span>

        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Corporate:</span>
              <span class="text--secondary">{{ corporateData.name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2"> Email:</span>
              <span
                v-if="corporateData.email"
                class="text--secondary"
              >{{ corporateData.email }}</span>
              <span
                v-else
                class="text--secondary opacity-5"
              >email@example.com</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2"> Location:</span>
              <span
                v-if="corporateData.location"
                class="text--secondary"
              >{{ corporateData.location }}</span>
              <span
                v-else
                class="text--secondary opacity-5"
              >N/A</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Status
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Vaccinated:</span>
              <span class="text--secondary">{{ corporateData.vaccinated }} pax</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Requested:</span>
              <span class="text--secondary">{{ corporateData.requested }} pax</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Amount Paid:</span>
              <span class="text--secondary">${{ corporateData.amount }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { avatarText, kFormatter } from '@core/utils/filter'
export default {
  name: 'corporate-data',
  components: {},
  props: {
    corporateData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    resolveVaccineAmount: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
      kFormatter,
    }
  },
}
</script>

<style>
</style>
