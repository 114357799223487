<template>
  <div>
    <!-- alert colors-->
    <v-card
      title="Colors"
      class="mb-6"
    >
      <v-card-title>
        Vaccine Requests
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="corporateData.vaccination_requests"
        hide-default-footer
      >
        <!-- Table Rows -->
        <template #[`item.corporate`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="corporateData.icon ? '' : 'primary'"
              :class="corporateData.icon ? '' : 'v-avatar-light-bg success--text'"
              size="33"
              class="me-3"
            >
              <v-img
                v-if="corporateData.icon"
                :src="require(`@/assets/images/company/${corporateData.icon}`)">
              </v-img>
              <span
                v-else
                class="font-weight-semibold"
              >{{ avatarText(corporateData.name) }}</span>
            </v-avatar>
            <div class="text-no-wrap">
              <p class="font-weight-medium text--primary mb-n1">
                {{ corporateData.name }}
              </p>
              <span class="text-xs text--disabled">{{corporateData.location}}</span>
            </div>
          </div>
        </template>

        <!-- location -->
        <template #[`item.request`]="{item}">
          <router-link
            :to="{ name: 'corporates-requests-view', params: { id: item.id }}"
            class="text--secondary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ item.id.split('-')[0].toUpperCase()}}
          </router-link>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveStatusVariant(item.status)"
            :class="`${resolveStatusVariant(item.status['name'])}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status.name }}
          </v-chip>
        </template>

        <!-- target -->
        <template #[`item.target`]="{item}">
          <span>{{ corporateData.is_csr ? 'CSR Request' : 'Staff Request' }}</span>
        </template>

        <!-- amount -->
        <template #[`item.amount`]="{item}">
          <span>${{ resolveVaccineRequestAmount(item) }}</span>
        </template>

        <!-- paid -->
        <template #[`item.paid`]="{item}">
          <span>${{ resolveVaccineRequestAmount(item) }}</span>
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
} from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import resolvers from '@/mixins/resolvers'

export default {
  name: 'vaccine-requests',
  components: {},
  mixins: [resolvers],
  props: {
    corporateData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const tableColumnHeaders = [
      {
        text: 'CORPORATES',
        value: 'corporate',
        sortable: false,
      },
      { text: 'REQUEST', value: 'request', sortable: false },
      { text: 'STATUS', value: 'status', sortable: false },
      { text: 'TYPE', value: 'target', sortable: false },
      { text: 'QUANTITY', value: 'quantity', sortable: false },
      { text: 'AMOUNT', value: 'amount', sortable: false },
      { text: 'PAID', value: 'paid', sortable: false },
    ]

    const resolveVaccineRequestAmount = item => {
      var price = item.price ?? 10
      var quantity = item.quantity ?? 0

      return (price * quantity).toString().toAccounting()
    }

    String.prototype.toAccounting = function () {
      return parseFloat(this)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    }

    return {
      avatarText,
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiFileDocumentOutline,
        mdiDeleteOutline,
        mdiExportVariant,
        mdiAccountOutline,
      },
      tableColumnHeaders,
      resolveVaccineRequestAmount,
    }
  },
}
</script>
