import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCorporates(ctx, queryParams) {
      const limit = queryParams.options.itemsPerPage
      const offset = (queryParams.options.page - 1) * limit
      const sort = queryParams.options.sortDesc.length == 1 ? 'sort_asc' : 'sort_desc'
      const filter = queryParams.q == '' ? '' : '&name=' + queryParams.q

      return new Promise((resolve, reject) => {
        axios
          .get(`/corporate?limit=${limit}&offset=${offset}&sort_by=name&${sort}=true${filter}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCorporate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/corporate/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
