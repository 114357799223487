export default function useCorporateView() {
  const resolveVaccineQuantities = requests => {
    return resolveRequestedQuantity(requests)
  }

  const resolveVaccineAmount = requests => {
    return (resolveRequestedQuantity(requests) * 10).toString().toAccounting()
  }

  const resolveRequestedQuantity = requests => {
    var requested = 0
    requests.forEach(function(item) {
      requested += item['quantity'] ?? 0
    })
    return requested
  }

  const resolveVaccinatedCount = requests => {
    return requests.length
  }

  String.prototype.toAccounting = function() {
    return parseFloat(this)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
  }

  return {
    resolveVaccineQuantities,
    resolveVaccineAmount,
    resolveVaccinatedCount,
  }
}
