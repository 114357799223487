<template>
  <div id="corporate-vaccination-tab">
    <v-card
      title="Colors"
      class="mb-6"
    >
      <v-card-title>
        Patient Vaccinations
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="corporateData.vaccinations"
        hide-default-footer
      >
        <!-- Date -->
        <template #[`item.date`]="{item}">
          <span>{{item.date_administered == null ? '01/12' : item.date_administered}}</span>
        </template>

        <!-- Patient -->
        <template #[`item.patient`]="{item}">
          <div class="text-no-wrap">
            <p class="font-weight-medium text--primary mb-n1">
              Name Withheld
            </p>
          </div>
        </template>

        <!-- Batch -->
        <template #[`item.batch`]="{item}">
          <span>{{item.batch_no == null ? 'N/A' : item.batch_no}}</span>
        </template>

        <!-- Status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveStatusVariant(item.vaccination_status.name)"
            :class="`${resolveStatusVariant(item.vaccination_status.name)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.vaccination_status.name }}
          </v-chip>
        </template>

        <!-- Request -->
        <template #[`item.request`]="{item}">
          <router-link
            :to="{ name: 'corporates-requests-view', params: { id: item.vaccine_request_id }}"
            class="text--secondary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ item.vaccine_request_id.split('-')[0].toUpperCase()}}
          </router-link>
        </template>

        <!-- Narration -->
        <template #[`item.narration`]="{item}">
          <span>{{ item.vaccination_status.description }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import resolvers from '@/mixins/resolvers'
export default {
  name: 'corporate-vaccinations',
  components: {},
  mixins: [resolvers],
  props: {
    corporateData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const tableColumnHeaders = [
      {
        text: 'DATE',
        value: 'date',
        sortable: false,
      },
      { text: 'PATIENT', value: 'patient', sortable: false },
      { text: 'REQUEST', value: 'request', sortable: false },
      { text: 'BATCH', value: 'batch', sortable: false },
      { text: 'STATUS', value: 'status', sortable: false },
      { text: 'NARRATION', value: 'narration', sortable: false },
    ]

    return {
      tableColumnHeaders,
    }
  },
}
</script>
