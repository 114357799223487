<template>
  <div id='corporate-view'>
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="3"
      >
        <corporate-data
          :corporate-data="corporateData"
          :resolve-vaccine-quantities="resolveVaccineQuantities"
          :resolve-vaccine-amount="resolveVaccineAmount"
          ></corporate-data>
      </v-col>
      <v-col
        cols="12"
        md="7"
        lg="9"
      >
        <v-tabs
          v-model="corporateTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="corporate-tabs-content"
          v-model="corporateTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <vaccine-requests
              :corporate-data="corporateData"
            >
            </vaccine-requests>
          </v-tab-item>

          <v-tab-item>
            <corporate-vaccinations
              :corporate-data="corporateData"
            >
            </corporate-vaccinations>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import { mdiCalendarClockOutline, mdiFormatListChecks } from '@mdi/js'
import router from '@/router'
import store from '@/store'
import StoreModule from '../StoreModule'
import useCorporateView from './useCorporateView'

// components
import CorporateData from './corporate-data-panel/CorporateData.vue'
import VaccineRequests from './corporate-vaccine-requests/VaccineRequests.vue'
import CorporateVaccinations from './corporate-vaccinations/CorporateVaccinations.vue'

export default {
  components: {
    CorporateData,
    VaccineRequests,
    CorporateVaccinations,
  },
  setup() {
    const CORPORATES_STORE_MODULE_NAME = 'corporate'
    if (!store.hasModule(CORPORATES_STORE_MODULE_NAME)) store.registerModule(CORPORATES_STORE_MODULE_NAME, StoreModule)

    const corporateTab = ref(null)
    const corporateData = ref({})
    const requests = ref([])
    const { resolveVaccineQuantities, resolveVaccineAmount, resolveVaccinatedCount } = useCorporateView()

    store
      .dispatch('corporate/fetchCorporate', { id: router.currentRoute.params.id })
      .then(response => {
        corporateData.value = response.data.result
        corporateData.value['requested'] = resolveVaccineQuantities(response.data.result.vaccination_requests)
        corporateData.value['vaccinated'] = resolveVaccinatedCount(response.data.result.vaccinations)
        corporateData.value['amount'] = resolveVaccineAmount(response.data.result.vaccination_requests)
      })
      .catch(error => {
        corporateData.value = {}
        console.log('nothing')
      })

    const tabs = [
      { icon: mdiCalendarClockOutline, title: 'Requests' },
      { icon: mdiFormatListChecks, title: 'Vaccinations' },
    ]

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CORPORATES_STORE_MODULE_NAME)) store.unregisterModule(CORPORATES_STORE_MODULE_NAME)
    })

    return {
      corporateTab,
      corporateData,
      requests,
      tabs,
      resolveVaccineQuantities,
      resolveVaccineAmount,
      resolveVaccinatedCount,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
#corporate-view #corporate-tabs-content {
  background-color: transparent;
}
</style>
