var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-6",attrs:{"title":"Colors"}},[_c('v-card-title',[_vm._v(" Vaccine Requests ")]),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.corporateData.vaccination_requests,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.corporate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"me-3",class:_vm.corporateData.icon ? '' : 'v-avatar-light-bg success--text',attrs:{"color":_vm.corporateData.icon ? '' : 'primary',"size":"33"}},[(_vm.corporateData.icon)?_c('v-img',{attrs:{"src":require(("@/assets/images/company/" + (_vm.corporateData.icon)))}}):_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.avatarText(_vm.corporateData.name)))])],1),_c('div',{staticClass:"text-no-wrap"},[_c('p',{staticClass:"font-weight-medium text--primary mb-n1"},[_vm._v(" "+_vm._s(_vm.corporateData.name)+" ")]),_c('span',{staticClass:"text-xs text--disabled"},[_vm._v(_vm._s(_vm.corporateData.location))])])],1)]}},{key:"item.request",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text--secondary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'corporates-requests-view', params: { id: item.id }}}},[_vm._v(" "+_vm._s(item.id.split('-')[0].toUpperCase())+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveStatusVariant(item.status['name'])) + "--text"),attrs:{"small":"","color":_vm.resolveStatusVariant(item.status)}},[_vm._v(" "+_vm._s(item.status.name)+" ")])]}},{key:"item.target",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.corporateData.is_csr ? 'CSR Request' : 'Staff Request'))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(_vm.resolveVaccineRequestAmount(item)))])]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(_vm.resolveVaccineRequestAmount(item)))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }